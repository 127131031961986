/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable simple-import-sort/imports */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { DUMMY_PRIORITIES_ARRAY } from "../../../assets/constants";
import axios from "../../../utils/api-client";
import { appendPriorityToSkills } from "../../../utils/helpers";
import { Priority, SkillItem } from "../../../utils/types";
import {
	CreateWorkerPayload,
	JobCollection,
	JobPriority,
	ManageWorkerState,
	OtherSkill,
	PriorityType,
	Skill,
	SkillMatrix_Job,
	SkillMatrix_JobRole,
	SkillMatrix_JobSkill,
	SkillMatrix_ResponseJobModel,
	SkillMatrix_Skill,
	SkillMatrix_SkillLevel,
	SkillMatrix_SkillPriority,
	SkillMatrix_Worker,
	WorkerCert,
	Worker_BasicDetails,
	Worker_Job,
	Worker_ScoreItem,
} from "./manage-worker-types";
import { closeAddWorkerDialogReducer } from "./reducers/closeAddWorkerDialogReducer";
import { closeEditWorkerDialogReducer } from "./reducers/closeEditWorkerDialogReducer";
import { openAddWorkerDialogReducer } from "./reducers/openAddWorkerDialogReducer";
import { resetCreateNewWorkerReducer } from "./reducers/resetCreateNewWorkerReducer";
import { resetDeleteWorkersReducer } from "./reducers/resetDeleteWorkersReducer";
import { resetEditWorkerReducer } from "./reducers/resetEditWorkerReducer";
import { setSupervisorRateLimitError } from "./../../../features/user/user-slice";

//Not in use
export const fetchAllWorkers = createAsyncThunk(
	"manageWorkers/fetchAllWorkers",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const workers = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/workers`
			);
			//console.log(workers)
			return fulfillWithValue(workers.data);
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const fetchAllOrganizations = createAsyncThunk(
	"manageWorkers/fetchAllOrganizations",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const organizations = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/organizations`
			);
			return fulfillWithValue(organizations.data);
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.message);
		}
	}
);

// export const fetchAllRegularShiftTypes = createAsyncThunk(
// 	"manageWorkers/fetchAllRegularShiftTypes",
// 	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
// 		try {
// 			const regularShiftTypes = await axios.get(
// 				`${process.env.REACT_APP_API_END_POINT}/RShifts/AllTypes`
// 			);
// 			return fulfillWithValue(regularShiftTypes.data);
// 		} catch (error) {
// 			return rejectWithValue(error.message);
// 		}
// 	}
// );

export const fetchOrganizationTypes = createAsyncThunk(
	"manageWorkers/fetchOrganizationTypes",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const organizationTypes = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/organizationtypes`
			);
			return fulfillWithValue(organizationTypes.data);
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.value);
		}
	}
);

export const fetchAllManagers = createAsyncThunk(
	"manageWorkers/fetchAllManagers",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const organizations = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/managers`
			);
			return fulfillWithValue(organizations.data);
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getExpLevel = createAsyncThunk(
	"manageWorkers/getExpLevel",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const workers = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/workers/getexplevels`
			);
			return fulfillWithValue(workers.data);
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const fetchWorkerProfile = createAsyncThunk(
	"manageWorkers/fetchWorkerProfile",
	/**  @param payload {workerId:string} */
	async (
		payload: string,
		{ dispatch, fulfillWithValue, rejectWithValue, getState }
	) => {
		try {
			const workers = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/workerprofile/${payload}`
			);
			return fulfillWithValue({ ...workers.data, workerId: payload });
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const fetchSkillLevels = createAsyncThunk(
	"manageWorkers/skillLevels",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const getSkillLevels = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/Levels`
			);
			return fulfillWithValue(getSkillLevels.data);
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const fetchSkillMatrix = createAsyncThunk(
	"manageWorkers/fetchSkillMatrix",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const getSkillMatrix = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/SkillsMatrix/GetForUser`,
				payload
			);
			return fulfillWithValue(getSkillMatrix.data);
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const fetchSkillMatrixFilters = createAsyncThunk(
	"manageWorkers/fetchSkillMatrixFilters",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const getSkillMatrixFilters = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/SkillsMatrix/GetFiltersV2`
			);
			return fulfillWithValue(getSkillMatrixFilters.data);
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const fetchNotAssignedSkillLevel = createAsyncThunk(
	"manageWorkers/fetchNotAssignedSkillLevel",
	async (
		payload,
		{ dispatch, fulfillWithValue, rejectWithValue, getState }
	) => {
		try {
			const companyId = (getState() as any).user.userData.companyId;
			const getNotAssignedSkillLevel = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/SkillRepresentation/GetNoWorkerSkillRep/?companyId=${companyId}`
			);
			if (getNotAssignedSkillLevel.status === 200) {
				return fulfillWithValue(getNotAssignedSkillLevel.data);
			} else if (getNotAssignedSkillLevel.status === 204) {
				return fulfillWithValue(undefined);
			} else {
				return rejectWithValue("An error occurred");
			}
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const fetchSkillMatrixDefaultFilters = createAsyncThunk(
	"manageWorkers/fetchSkillMatrixDefaultFilters",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const getSkillMatrixFilters = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/SkillsMatrix/GetDefaultFilters`
			);
			return fulfillWithValue(getSkillMatrixFilters.data);
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const fetchManageWorkersData = createAsyncThunk(
	"manageWorkers/fetchManageWorkersData",
	async (
		payload: { getAll: boolean; version: number },
		{ dispatch, fulfillWithValue, rejectWithValue, getState }
	) => {
		const userId = (getState() as any).user.userData.id;
		// let getAll = payload.getAll ?? false;
		const version = payload.version ?? 1;
		// let url = `${process.env.REACT_APP_API_END_POINT}/companies/workers?getAll=${getAll}`;
		let url = `${process.env.REACT_APP_API_END_POINT}/SkillsMatrix/GetForUserV2`;
		if (version === 2) {
			url = `${process.env.REACT_APP_API_END_POINT}/companies/v2/workers`;
		}
		try {
			const getAllWorkers = axios.get(url);
			const getAllJobRoles = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/GetJobsByUser`,
				{
					params: {
						userid: userId,
					},
				}
			);
			const getAllSkills = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/GetSkillsByUser`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				}
			);

			let [allWorkers, allSkills, allJobRoles] = await Promise.all([
				getAllWorkers,
				getAllSkills,
				getAllJobRoles,
			]);

			allWorkers = allWorkers.data;
			allSkills = allSkills.data;
			allJobRoles = allJobRoles;
			const allJobRolesData: SkillMatrix_JobRole[] = allJobRoles.data;

			const normalizedJobRoles: JobCollection = {};

			allJobRolesData.length > 0 &&
				allJobRolesData.forEach((jobRole: SkillMatrix_JobRole) => {
					normalizedJobRoles[jobRole.id] = jobRole;
				});

			return fulfillWithValue({ allWorkers, allSkills, normalizedJobRoles });
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			// console.error(error);
			return rejectWithValue(error.message);
		}
	}
);

export const addSkillInterested = createAsyncThunk(
	"manageWorker/addSkillInterested",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const skillInterested = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/workers/SkillInterest/${payload}/A`
			);
			const data = skillInterested.data;
			return fulfillWithValue(data);
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			if (error.message === "Request failed with status code 400")
				return rejectWithValue("Error adding skill interested.");
			else return rejectWithValue(error.response.data);
		}
	}
);

export const rejectSkillInterested = createAsyncThunk(
	"manageWorker/rejectSkillInterested",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const skillInterested = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/workers/SkillInterest/${payload}/R`
			);
			const data = skillInterested.data;
			return fulfillWithValue(data);
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			if (error.message === "Request failed with status code 400")
				return rejectWithValue("Error adding skill interested.");
			else return rejectWithValue(error.response.data);
		}
	}
);

export const createNewWorker = createAsyncThunk(
	"manageWorkers/createNewWorker",
	async (
		payload: CreateWorkerPayload,
		{ dispatch, fulfillWithValue, rejectWithValue, getState }
	) => {
		const {
			firstName,
			lastName,
			companyEmployeeId,
			mobilePhoneNumber,
			// workerTypeId,
			jobs,
			jobTitle,
			workerJobs,
			skills,
			joiningDate,
			orgUnitIds,
			locationId,
			rShiftIds,
			managerUserId,
			workCenterId,
			shiftPatternId,
			primaryJobId,
		} = payload;
		let workerTypeId = payload.workerTypeId;
		let orgIds = "";
		if (orgUnitIds.length !== 0) {
			orgIds = orgUnitIds.toString();
		}

		let jobIds: string[] | string = [];
		if (jobs) {
			jobIds = Object.keys(jobs).reduce((acc: string[], key: string) => {
				if (jobs[key] === true) acc.push(key);
				return acc;
			}, []);
		}

		if (jobIds.length !== 0) {
			jobIds = jobIds.toString();
		} else {
			// return rejectWithValue("Please select at least one job role");
			jobIds = "";
		}
		workerTypeId = Number.parseInt(workerTypeId);

		const user = (getState() as any).user.userData.id;
		const phone = mobilePhoneNumber
			.split("")
			.reduce((acc: string, curr: string) => {
				if (Number.isInteger(Number.parseInt(curr))) {
					acc += curr;
				}
				return acc;
			}, "+");

		const workerData = {
			firstName,
			lastName,
			companyEmployeeId,
			mobilePhoneNumber: phone.length > 1 ? phone : null,
			workerTypeId,
			jobIds,
			jobTitle,
			skills,
			createdBy: `${user}`,
			// companyId: 1,
			workerJobs,
			joiningDate,
			orgUnitIds: orgIds,
			locationId,
			rShiftIds,
			managerUserId,
			workCenterId,
			shiftPatternId,
			primaryJobId,
		};
		try {
			const newWorker = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Workers/Create`,
				workerData
			);
			const data = newWorker.data;
			if (newWorker.status === 200) {
				return fulfillWithValue(data);
			} else {
				return rejectWithValue("Error while creating new worker");
			}
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			if (error.response.status === 500 || error.response.status === 400)
				return rejectWithValue(error.response.data.message);
			else if (Object.prototype.hasOwnProperty.call(error, "message")) {
				if (
					error.message === "Request failed with status code 500" ||
					error.message === "Request failed with status code 400"
				) {
					if (error.response.data.message) {
						return rejectWithValue(error.response.data.message);
					} else {
						return rejectWithValue("Error while creating new worker");
					}
				} else {
					return rejectWithValue(error.message);
				}
			} else if (
				Object.prototype.hasOwnProperty.call(error, "MobilePhoneNumber")
			) {
				if (error.MobilePhoneNumber[0]) {
					return rejectWithValue(error.MobilePhoneNumber[0]);
				} else {
					return rejectWithValue("Error while creating new worker");
				}
			} else {
				return rejectWithValue("Error while creating new worker");
			}
		}
	}
);

export const deleteWorkers = createAsyncThunk(
	"manageWorkers/deleteWorkers",
	async (
		payload: string[],
		{ dispatch, fulfillWithValue, rejectWithValue }
	) => {
		try {
			const response = await axios.delete(
				`${process.env.REACT_APP_API_END_POINT}/Workers/Delete`,
				{
					params: {
						workerIds: payload.toString(),
					},
				}
			);
			if (response.status === 200) return fulfillWithValue(payload);
			else return rejectWithValue("An error occurred");
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			if (error.response.status === 500)
				return rejectWithValue(error.response.data.message);
			return rejectWithValue(error.message);
		}
	}
);

export const editWorker = createAsyncThunk(
	"manageWorkers/editWorker",
	async (
		payload: CreateWorkerPayload,
		{ dispatch, fulfillWithValue, rejectWithValue, getState }
	) => {
		const workerId = (getState() as any).manageWorkers.editWorkerDialog
			.workerId;
		const allWorkers = (getState() as any).manageWorkers.allWorkers;
		let oldWorkerData = allWorkers.filter(
			(worker: any) => worker.id === workerId
		);
		if (payload?.ignoreExistingShifts !== undefined) {
			const {
				firstName,
				lastName,
				mobilePhoneNumber,
				// workerTypeId,
				//  ignoreExistingShifts,
				//  removeWorkerFromShifts,
				jobs,
				jobTitle,
				skills,
				workerJobs,
				joiningDate,
				orgUnitIds,
				companyEmployeeId,
				locationId,
				rShiftIds,
				managerUserId,
				workCenterId,
				shiftPatternId,
				primaryJobId,
			} = payload;
			let workerTypeId = payload.workerTypeId;
			let orgIds = "";
			if (orgUnitIds.length !== 0) {
				orgIds = orgUnitIds.toString();
			}

			let jobIds: string[] | string = [];

			jobIds = Object.keys(jobs).reduce((acc: string[], key: string) => {
				if (jobs[key] === true) acc.push(key);
				return acc;
			}, []);

			if (jobIds.length !== 0) {
				jobIds = jobIds.toString();
			} else {
				//return rejectWithValue("Please select at least one job role");
				jobIds = "";
			}
			workerTypeId = Number.parseInt(workerTypeId);

			// Sending jobId in request
			// It's jobIds in data

			const user = (getState() as any).user.userData.id;
			const phone =
				mobilePhoneNumber.length > 3
					? mobilePhoneNumber.split("").reduce((acc: any, curr: string) => {
							if (Number.isInteger(Number.parseInt(curr))) {
								acc += curr;
							}
							return acc;
					  }, "+")
					: null;
			const workerData = {
				firstName,
				lastName,
				mobilePhoneNumber: phone.length > 1 ? phone : null,
				workerTypeId,
				jobId: jobIds,
				jobTitle,
				skills,
				workerJobs,
				updatedBy: user,
				joiningDate,
				orgUnitIds: orgIds,
				companyEmployeeId,
				locationId,
				rShiftIds,
				managerUserId,
				workCenterId,
				shiftPatternId,
				primaryJobId,
			};

			oldWorkerData = oldWorkerData[0];

			let requestPayload = {
				...oldWorkerData,
				...workerData,
			};

			if (payload?.ignoreExistingShifts !== undefined)
				requestPayload = {
					...requestPayload,
					ignoreExistingShifts: true,
					removeWorkerFromShifts: payload?.removeWorkerFromShifts,
				};

			try {
				const editedWorker = await axios.put(
					`${process.env.REACT_APP_API_END_POINT}/Workers/Put`,
					requestPayload
				);

				if (editedWorker.status === 204) {
					requestPayload["jobIds"] = requestPayload?.jobId;

					switch (requestPayload["workerTypeId"]) {
						case 1:
							requestPayload["type"] = "FullTime";
							break;
						case 2:
							requestPayload["type"] = "PartTime";
							break;
						case 3:
							requestPayload["type"] = "FlexWorker";
							break;
						default:
							requestPayload["type"] = "FullTime";
							break;
					}
					// requestPayload["workerTypeId"] = requestPayload["workerTypeId"].toString();
					return fulfillWithValue({ workerId, data: requestPayload });
				}
			} catch (error: any) {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				if (error.response.data.shifts && error.response.data.shifts.length > 0)
					return rejectWithValue(error.response.data);
				if (error.message === "Request failed with status code 400")
					return rejectWithValue({ message: "An error occurred" });
				return rejectWithValue({ message: error.response.data });
			}
		} else {
			const {
				firstName,
				lastName,
				mobilePhoneNumber,
				// workerTypeId,
				jobs,
				jobTitle,
				skills,
				workerJobs,
				joiningDate,
				orgUnitIds,
				companyEmployeeId,
				locationId,
				rShiftIds,
				managerUserId,
				workCenterId,
				shiftPatternId,
				primaryJobId,
			} = payload;
			let workerTypeId = payload.workerTypeId;
			let orgIds = "";
			if (orgUnitIds.length !== 0) {
				orgIds = orgUnitIds.toString();
			}
			let jobIds: string[] | string = Object.keys(jobs).reduce(
				(acc: string[], key: string) => {
					if (jobs[key] === true) acc.push(key);
					return acc;
				},
				[]
			);

			if (jobIds.length !== 0) {
				jobIds = jobIds.toString();
			} else {
				//return rejectWithValue("Please select at least one job role");
				jobIds = "";
			}
			workerTypeId = Number.parseInt(workerTypeId);

			// Sending jobId in request
			// It's jobIds in data

			const user = (getState() as any).user.userData.id;
			const phone = mobilePhoneNumber
				.split("")
				.reduce((acc: string, curr: string) => {
					if (Number.isInteger(Number.parseInt(curr))) {
						acc += curr;
					}
					return acc;
				}, "+");

			const workerData = {
				firstName,
				lastName,
				mobilePhoneNumber: phone.length > 3 ? phone : null,
				workerTypeId,
				jobId: jobIds,
				jobTitle,
				workerJobs,
				skills,
				updatedBy: user,
				joiningDate,
				orgUnitIds: orgIds,
				companyEmployeeId,
				locationId,
				rShiftIds,
				managerUserId,
				workCenterId,
				shiftPatternId,
				primaryJobId,
			};

			oldWorkerData = oldWorkerData[0];

			const requestPayload = {
				...oldWorkerData,
				...workerData,
			};

			try {
				const editedWorker = await axios.put(
					`${process.env.REACT_APP_API_END_POINT}/Workers/Put`,
					requestPayload
				);

				if (editedWorker.status === 204) {
					requestPayload["jobIds"] = requestPayload?.jobId;

					switch (requestPayload["workerTypeId"]) {
						case 1:
							requestPayload["type"] = "FullTime";
							break;
						case 2:
							requestPayload["type"] = "PartTime";
							break;
						case 3:
							requestPayload["type"] = "FlexWorker";
							break;
						default:
							requestPayload["type"] = "FullTime";
							break;
					}
					// requestPayload["workerTypeId"] = requestPayload["workerTypeId"].toString();
					return fulfillWithValue({ workerId, data: requestPayload });
				}
			} catch (error: any) {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				if (error.response.data.shifts && error.response.data.shifts.length > 0)
					return rejectWithValue(error.response.data);

				if (error.response.status === 500 || error.response.status === 400)
					return rejectWithValue({ message: error.response.data.message });
				if (error.message === "Request failed with status code 400")
					return rejectWithValue({ message: "An error occurred" });
				return rejectWithValue({ message: error.response.data });
			}
		}
	}
);

export const updateWorkerJobExpiry = createAsyncThunk(
	"manageWorkers/updateWorkerJobExpiry",
	async (
		payload: { status: boolean; jobId: number; workerId: string },
		{ dispatch, fulfillWithValue, rejectWithValue }
	) => {
		const { status, jobId, workerId } = payload;
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/workers/workerjob`,
				{
					workerId: workerId,
					JobId: jobId,
					IsExpired: status,
				}
			);
			if (response.status === 200) return fulfillWithValue(payload);
			else
				return rejectWithValue({ error: "An error occurred", data: payload });
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			if (error.response.status === 500) {
				return rejectWithValue({
					error: error.response.data.message ?? "An error occurred",
					data: payload,
				});
			} else {
				return rejectWithValue({
					error: error.message ?? "An error occurred",
					data: payload,
				});
			}
		}
	}
);

//skills matrix call
export const updateWorkerSkillLevel = createAsyncThunk(
	"manageWorker/updateWorkerSkillLevel",
	async (
		payload,
		{ dispatch, fulfillWithValue, rejectWithValue, getState }
	) => {
		try {
			const useObsoleteSkills = (getState() as any).user.userData
				.useObsoleteSkills;
			const skillPriorities = (getState() as any).manageWorkers
				.skillMatrixFiltersSkillPriorities;
			const skillLevels = (getState() as any).manageWorkers
				.skillMatrixFiltersSkillLevels;
			const skills = (getState() as any).manageWorkers.skillMatrixFiltersSkills;

			const res = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/workers/SkillLevel`,
				payload
			);
			if (res.status === 200) {
				const data = {
					payload: payload,
					priorities: skillPriorities,
					levels: skillLevels,
					skills: skills,
					useObsoleteSkills: useObsoleteSkills,
				};
				return fulfillWithValue(data);
			}

			return fulfillWithValue(payload);
		} catch (error: any) {
			if (error.message === "Request failed with status code 400")
				return rejectWithValue("Error adding skill interested.");
			else return rejectWithValue(error.response.data);
		}
	}
);

export const getWorkerTypes = createAsyncThunk(
	"manageWorker/get-worker-type",
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Workers/Types`
			);
			if (res.status === 200) {
				return fulfillWithValue(res.data);
			} else if (res.status === 204) {
				return fulfillWithValue([]);
			}
			return rejectWithValue("An error occurred");
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// Check employee Id or worker Id uniqueness
export const isEmployeeIdUnique = createAsyncThunk(
	"manageWorker/isEmployeeIdUnique",
	async (payload: any, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Workers/IsEmployeeIdUnique?companyEmployeeId=${payload}`
			);
			if (res.status === 200) {
				return fulfillWithValue({
					employeeId: payload,
					isUnique: res.data,
				});
			} else {
				return fulfillWithValue({
					employeeId: payload,
					isUnique: false,
				});
			}
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// Fetch all locations by user id
export const fetchUserLocations = createAsyncThunk(
	"manageWorker/fetchUserLocations",
	async (
		payload,
		{ dispatch, fulfillWithValue, rejectWithValue, getState }
	) => {
		try {
			const userId = (getState() as any).user.userData.id;
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Locations/GetLocationsByUser?userid=${userId}`
			);
			if (res.status === 200) {
				return fulfillWithValue(res.data);
			} else if (res.status === 204) {
				return fulfillWithValue([]);
			}
			return rejectWithValue("An error occurred");
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to get all Teams by location
export const getLocationShiftPatterns = createAsyncThunk(
	"manageWorkers/get-location-shift-patterns",
	/**  @param payload {locationId:number} */
	async (
		payload: { locationId: number },
		{ dispatch, fulfillWithValue, rejectWithValue }
	) => {
		try {
			const { locationId } = payload;
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/ftshifts/patterns/${locationId}`
			);
			return fulfillWithValue(res.data);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to get all shift types
export const getAllShiftTypes = createAsyncThunk(
	"manageWorkers/get-all-shift-types",
	/**  @param payload {locationId:number} */
	async (
		payload: { locationId: number },
		{ dispatch, fulfillWithValue, rejectWithValue }
	) => {
		const { locationId } = payload;
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/ftshifts/types/${locationId}`
			);
			if (res.status === 200) return fulfillWithValue(res.data);
			else if (res.status === 204) return fulfillWithValue([]);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to delete skills for worker
export const deleteWorkerSkills = createAsyncThunk(
	"manageWorkers/delete-worker-skills",
	/**  @param payload {workerId:string, skillId:number} */
	async (
		payload: { workerId: string; skillId: number },
		{ dispatch, fulfillWithValue, rejectWithValue }
	) => {
		const { workerId, skillId } = payload;
		try {
			const res = await axios.delete(
				`${process.env.REACT_APP_API_END_POINT}/workers/skill/${workerId}/${skillId}`
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 401)
					return rejectWithValue({
						message:
							"The Worker you are trying to update belongs to a Workcenter you do not have access to. You cannot update the Worker's Skills.",
					});
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to delete certifications for worker
export const deleteWorkerCertifications = createAsyncThunk(
	"manageWorkers/delete-worker-certifications",
	/**  @param payload {workerCertId:string} */
	async (
		payload: { workerCertId: string },
		{ dispatch, fulfillWithValue, rejectWithValue }
	) => {
		const { workerCertId } = payload;
		try {
			const res = await axios.delete(
				`${process.env.REACT_APP_API_END_POINT}/workers/cert/${workerCertId}`
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to add worker skills
export const addWorkerSkills = createAsyncThunk(
	"manageWorkers/add-worker-skills",
	/**  @param payload {workerId:string, skillId:number}[] */
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		// const { workerId, skillId } = payload;
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/workers/skill/`,
				payload
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 401)
					return rejectWithValue({
						message:
							"The Worker you are trying to update belongs to a Workcenter you do not have access to. You cannot update the Worker's Skills.",
					});
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to modify worker skill
export const modifyWorkerSkill = createAsyncThunk(
	"manageWorkers/modify-worker-skill",
	/**  @param payload {workerId:string, skillId:number, levelId:number, validFrom: string, validTo: string} */
	async (
		payload: {
			workerId: string;
			skillId: number;
			levelId: number;
			validFrom: string;
			validTo: string;
		},
		{ dispatch, fulfillWithValue, rejectWithValue, getState }
	) => {
		const { skillId, levelId, validFrom, validTo } = payload;
		const workerId = (getState() as any).manageWorkers.editWorkerDialog
			.workerId;
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/workers/skill`,
				[
					{
						WorkerId: workerId,
						SkillId: skillId,
						LevelId: levelId,
						ValidFrom: validFrom,
						ValidTo: validTo,
					},
				]
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 401)
					return rejectWithValue({
						message:
							"The Worker you are trying to update belongs to a Workcenter you do not have access to. You cannot update the Worker's Skills.",
					});
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to add worker certification

export const addWorkerCertifications = createAsyncThunk(
	"manageWorkers/add-worker-certifications",
	/**  @param payload {workerId:string, CompanyCertId:number}[] */
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/workers/cert/`,
				payload
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to edit worker certification
export const editWorkerCertification = createAsyncThunk(
	"manageWorkers/edit-worker-certification",
	/**  @param payload {workerId:string, certificationId:number, validFrom: string, validTo: string} */
	async (
		payload: WorkerCert,
		{ dispatch, fulfillWithValue, rejectWithValue, getState }: any
	) => {
		const { companyCertId, validFrom, validTo, isActive }: any = payload;
		const workerId = getState().manageWorkers.editWorkerDialog.workerId;
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/workers/cert`,
				[
					{
						WorkerId: workerId,
						CompanyCertId: companyCertId,
						ValidFrom: validFrom,
						ValidTo: validTo,
						isActive: isActive,
					},
				]
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to upload worker certification
export const uploadWorkerCertification = createAsyncThunk(
	"manageWorkers/upload-worker-certification",
	/**  @param payload {workerCertId : number, file: file} */
	async (
		payload: any,
		{ dispatch, fulfillWithValue, rejectWithValue, getState }
	) => {
		// const { workerCertId, file } = payload;
		const formData = new FormData();
		formData.append("WorkerCertId", payload.workerCertId);
		formData.append("File", payload.file);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/workers/certupload`,
				formData
			);
			if (res.status === 200) return fulfillWithValue(res.data);
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

export const deleteWorkerCertificationFile = createAsyncThunk(
	"manageWorkers/delete-worker-certification-file",
	/**  @param payload {workerCertId : number} */
	async (payload, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.delete(
				`${process.env.REACT_APP_API_END_POINT}/workers/certfile/${payload}`
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to download worker certificate
export const downloadWorkerCertification = createAsyncThunk(
	"manageWorkers/download-worker-certification",
	/**  @param payload {workerCertFileId : number, fileName: string} */
	async (
		payload: {
			workerCertFileId: number;
			fileName: string;
		},
		{ dispatch, fulfillWithValue, rejectWithValue }
	) => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/workers/certfileV2/${payload.workerCertFileId}`,
				{
					responseType: "blob",
					onDownloadProgress: (progressEvent) => {
						const progress = Math.round(
							(progressEvent.loaded * 100) / (progressEvent.total ?? 1)
						);
						// console.log("Download progress:", progress);
						dispatch(
							setDownloadProgress({
								workerCertFileId: payload.workerCertFileId,
								progress,
							})
						);
					},
				}
			);
			if (res.status === 200) {
				// Create a blob from the response data
				const blob = new Blob([res.data], {
					type: res.headers["content-type"],
				});
				const url = window.URL.createObjectURL(blob);

				// Extract the filename from the Content-Disposition header
				const contentDisposition = res.headers["content-disposition"];
				// let fileName = "downloaded-file";
				// if (contentDisposition) {
				// 	const matches = /filename="([^"]*)"/.exec(contentDisposition);
				// 	if (matches != null && matches[1]) {
				// 	fileName = matches[1];
				// }
				// }
				const fileName = payload.fileName;

				// Create a link element and trigger the download
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				dispatch(
					resetDownloadProgress({ workerCertFileId: payload.workerCertFileId })
				);
				return fulfillWithValue("");
			} else {
				return rejectWithValue("Failed to download file");
			}
		} catch (error: any) {
			try {
				const errorResponse = error?.response?.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

const initialState: ManageWorkerState = {
	addWorkerDialog: {
		isOpen: false,
	},
	editWorkerDialog: {
		isOpen: false,
		workerId: "",
		currentStep: 0,
	},
	editWorkerSkillDialog: {
		isOpen: false,
		skillData: null,
		certificationData: null,
	},
	editWorkerCertificationDialog: {
		isOpen: false,
		certificationData: null,
	},
	allWorkers: [],
	allOrganizations: [],
	allOrganizationTypes: [],
	allRegularShiftTypes: [],
	userLocations: [],
	allManagers: [],
	allJobRoles: {},
	allSkills: [],
	locationShiftPatterns: [],
	fetchAllWorkers: {
		status: "idle",
		errorMessage: "",
	},
	fetchAllOrganizations: {
		status: "idle",
		errorMessage: "",
	},
	// fetchAllRegularShiftTypes: {
	// 	status: "idle",
	// 	errorMessage: "",
	// },
	fetchOrganizationTypes: {
		status: "idle",
		errorMessage: "",
	},
	fetchUserLocations: {
		status: "idle",
		errorMessage: "",
	},
	fetchAllManagers: {
		status: "idle",
		errorMessage: "",
	},
	fetchManageWorkersData: {
		status: "idle",
		errorMessage: "",
	},
	createNewWorker: {
		status: "idle",
		errorMessage: "",
	},
	addWorkerSkills: {
		status: "idle",
		errorMessage: "",
	},
	addWorkerCertifications: {
		status: "idle",
		errorMessage: "",
	},
	deleteWorkers: {
		status: "idle",
		errorMessage: "",
	},
	deleteWorkerSkills: {
		status: "idle",
		errorMessage: "",
	},
	deleteWorkerCertifications: {
		status: "idle",
		errorMessage: "",
	},
	editWorkerCertification: {
		status: "idle",
		errorMessage: "",
	},
	editWorker: {
		status: "idle",
		errorMessage: "",
		shifts: [],
	},
	editWorkerSkill: {
		status: "idle",
		errorMessage: "",
	},
	updateWorkerJobExpiry: {
		status: "idle",
		errorMessage: "",
	},
	workerDetails: {
		data: null,
	},
	workerProfile: {
		status: "idle",
		errorMessage: "",
		profile: null,
		scoreItems: null,
		isOpen: false,
		workerId: "",
	},
	getExpLevel: {
		status: "idle",
		errorMessage: "",
	},
	addSkillInterested: {
		status: "idle",
		errorMessage: "",
	},
	rejectSkillInterested: {
		status: "idle",
		errorMessage: "",
	},
	updateSkillLevel: {
		status: "idle",
		errorMessage: "",
	},
	fetchSkillLevels: {
		status: "idle",
		errorMessage: "",
	},
	fetchSkillMatrix: {
		status: "idle",
		errorMessage: "",
	},
	fetchSkillMatrixFilters: {
		status: "idle",
		errorMessage: "",
	},
	fetchNotAssignedSkillLevel: {
		status: "idle",
		errorMessage: "",
		NotAssignedSkillLevel: undefined,
	},
	fetchSkillMatrixDefaultFilters: {
		status: "idle",
		errorMessage: "",
	},
	skillMatrixAppliedFilters: {
		locations: "",
		workcenters: "",
		jobs: "",
		managerId: 0,
		managers: "",
	},
	postUploadFiles: {
		status: "idle",
		successfulUploads: 0,
		failedUploads: 0,
	},
	postDeleteFiles: {
		status: "idle",
		successfulDeletes: 0,
		failedDeletes: 0,
	},
	skillMatrixData: {
		workers: [],
		totalCount: 0,
	},
	skillMatrixFiltersJobRoles: {},
	skillMatrixFiltersAllJobs: [],
	skillMatrixFiltersPriorities: [],
	skillMatrixFiltersOrgTypes: [],
	skillMatrixFiltersOrganizations: [],
	skillMatrixFiltersSkillPriorities: [],
	skillMatrixFiltersLocations: [],
	skillMatrixFiltersManagers: [],
	skillMatrixFiltersWorkCenters: [],
	skillMatrixFiltersShiftPatterns: [],
	skillMatrixFiltersWorkerTypes: [],
	skillMatrixFiltersSkills: [],
	skillMatrixFiltersSkillLevels: [],
	skillLevels: [], // This is company level skills levels
	expLevel: [], // This is exp Level of all workers for Worker table
	getWorkerTypes: {
		status: "idle",
		errorMessage: "",
	},
	getLocationShiftPatterns: {
		status: "idle",
		errorMessage: "",
	},
	workerTypes: [],
	getAllShiftTypes: {
		status: "idle",
		errorMessage: "",
		data: [],
	},
	downloadWorkerCertification: {
		status: "idle",
		errorMessage: "",
		progress: {},
		errors: {},
	},
	skillPriorities: [...DUMMY_PRIORITIES_ARRAY],
	isEmployeeIdUnique: {
		status: "idle",
		errorMessage: "",
		data: {
			originalEmployeeId: "",
			employeeId: "",
			isUnique: false,
			checked: false,
		},
	},
	jobSkillLevelMap: {},
};

export const ManageWorkersSlice = createSlice({
	name: "manageWorkers",
	initialState: initialState,
	reducers: {
		openAddWorkerDialog: openAddWorkerDialogReducer,
		closeAddWorkerDialog: closeAddWorkerDialogReducer,
		openEditWorkerSkillDialog: (state, action) => {
			state.editWorkerSkillDialog.isOpen = true;
			state.editWorkerSkillDialog.skillData = action.payload;
		},
		closeEditWorkerSkillDialog: (state) => {
			state.editWorkerSkillDialog.isOpen = false;
			state.editWorkerSkillDialog.skillData = null;
			state.editWorkerSkillDialog.certificationData = null;
		},
		openEditWorkerCertificationDialog: (state, action) => {
			state.editWorkerCertificationDialog.isOpen = true;
			state.editWorkerCertificationDialog.certificationData = action.payload;
		},
		closeEditWorkerCertificationDialog: (state) => {
			state.editWorkerCertificationDialog.isOpen = false;
			state.editWorkerCertificationDialog.certificationData = null;
		},
		setWorkerDetails: (state, action) => {
			state.workerDetails.data = action.payload;
		},
		resetWorkerDetails: (state) => {
			state.workerDetails.data = null;
			state.workerProfile.profile = null;
			state.workerProfile.scoreItems = null;
			state.workerProfile.errorMessage = "";
			state.workerProfile.status = "idle";
		},
		resetAddSkillInterested: (state) => {
			state.addSkillInterested.status = "idle";
			state.addSkillInterested.errorMessage = "";
		},
		openEditWorkerDialog: (state, action) => {
			state.editWorkerDialog.isOpen = true;
			state.editWorkerDialog.workerId = action.payload.workerId;
			state.editWorkerDialog.currentStep = action.payload.step;
			state.editWorker.status = "idle";
			state.editWorker.errorMessage = "";
		},
		changeEditWorkerStep: (state, action) => {
			state.editWorkerDialog.currentStep = action.payload.step;
		},
		closeEditWorkerDialog: closeEditWorkerDialogReducer,
		resetCreateNewWorker: resetCreateNewWorkerReducer,
		resetEditWorker: resetEditWorkerReducer,
		resetDeleteWorkers: resetDeleteWorkersReducer,
		resetWorkerJobStatus: (state) => {
			state.updateWorkerJobExpiry.status = "idle";
			state.updateWorkerJobExpiry.errorMessage = "";
		},
		resetSkillLevelStatus: (state) => {
			state.updateSkillLevel.status = "idle";
			state.updateSkillLevel.errorMessage = "";
		},
		resetSkillMatrixFilter: (state) => {
			state.fetchSkillMatrixFilters.status = "idle";
			state.fetchSkillMatrixFilters.errorMessage = "";
		},
		resetNotAssignedSkillLevel: (state) => {
			state.fetchNotAssignedSkillLevel.status = "idle";
			state.fetchNotAssignedSkillLevel.errorMessage = "";
			state.fetchNotAssignedSkillLevel.NotAssignedSkillLevel = undefined;
		},
		resetSkillMatrix: (state) => {
			state.fetchSkillMatrix.status = "idle";
			state.fetchSkillMatrix.errorMessage = "";
		},
		resetSkillMatrixDefaultFilters: (state) => {
			state.fetchSkillMatrixDefaultFilters.status = "idle";
			state.fetchSkillMatrixDefaultFilters.errorMessage = "";
		},
		setJobExpiry: (state: any, action: any) => {
			const { status, jobId, workerId } = action.payload;
			const index = state.allWorkers.findIndex((el: any) => el.id === workerId);
			const temp = state.allWorkers[index];
			state.allWorkers[index] = {
				...temp,
				workerJobs: temp.workerJobs.map((job: any) => {
					if (job.jobId === jobId) return { jobId: jobId, isExpired: status };
					return job;
				}),
			};
		},
		openWorkerProfile: (state, action) => {
			state.workerProfile.isOpen = true;
			state.workerProfile.workerId = action.payload;
		},
		closeWorkerProfile: (state) => {
			state.workerProfile.isOpen = false;
			state.workerProfile.workerId = "";
		},
		resetWorkerTypes: (state) => {
			state.getWorkerTypes.status = "idle";
			state.getWorkerTypes.errorMessage = "";
			state.workerTypes = [];
		},
		resetDeleteWorkerSkills: (state) => {
			state.deleteWorkerSkills.status = "idle";
			state.deleteWorkerSkills.errorMessage = "";
		},
		resetAddWorkerSkills: (state) => {
			state.addWorkerSkills.status = "idle";
			state.addWorkerSkills.errorMessage = "";
		},
		resetEditWorkerSkill: (state) => {
			state.editWorkerSkill.status = "idle";
			state.editWorkerSkill.errorMessage = "";
		},
		resetDeleteWorkerCertifications: (state) => {
			state.deleteWorkerCertifications.status = "idle";
			state.deleteWorkerCertifications.errorMessage = "";
		},
		resetAddWorkerCertifications: (state) => {
			state.addWorkerCertifications.status = "idle";
			state.addWorkerCertifications.errorMessage = "";
		},
		resetEditWorkerCertification: (state) => {
			state.editWorkerCertification.status = "idle";
			state.editWorkerCertification.errorMessage = "";
		},
		setUploadedFiles: (state: any, action) => {
			try {
				state.workerProfile.profile.workerCerts =
					state.workerProfile.profile.workerCerts.map((cert: any) => {
						if (cert.workerCertId === action.payload.workerCertId) {
							return {
								...cert,
								workerCertFiles: [...cert.workerCertFiles, action.payload],
							};
						}
						return cert;
					});
			} catch (e) {
				console.log(e);
			}
		},
		setDeletedFiles: (state: any, action) => {
			try {
				state.workerProfile.profile.workerCerts =
					state.workerProfile.profile.workerCerts.map((cert: any) => {
						// if (cert.workerCertId === action.payload.workerCertId) {
						return {
							...cert,
							workerCertFiles: cert.workerCertFiles.filter(
								(file: any) =>
									parseInt(file.workerCertFileId) !==
									parseInt(action.payload.workerCertFileId)
							),
						};
						// }
						// return cert;
					});
			} catch (e) {
				console.log(e);
			}
		},
		resetPostUploadFiles: (state) => {
			state.postUploadFiles.status = "idle";
			state.postUploadFiles.successfulUploads = 0;
			state.postUploadFiles.failedUploads = 0;
		},
		resetPostDeleteFiles: (state) => {
			state.postDeleteFiles.status = "idle";
			state.postDeleteFiles.successfulDeletes = 0;
			state.postDeleteFiles.failedDeletes = 0;
		},
		setPostUploadStatus: (state, action) => {
			state.postUploadFiles.status = action.payload;
		},
		setPostDeleteStatus: (state, action) => {
			state.postDeleteFiles.status = action.payload;
		},
		setDownloadProgress: (state: any, action) => {
			const { workerCertFileId, progress } = action.payload;
			state.downloadWorkerCertification.progress[workerCertFileId] = progress;
		},
		setDownloadError: (state: any, action) => {
			const { workerCertFileId, error } = action.payload;
			state.downloadWorkerCertification.errors[workerCertFileId] = error;
		},
		resetDownloadProgress: (state: any, action) => {
			const { workerCertFileId } = action.payload;
			delete state.downloadWorkerCertification.progress[workerCertFileId];
		},
		resetFetchAllOrganizations: (state) => {
			state.fetchAllOrganizations.status = "idle";
			state.fetchAllOrganizations.errorMessage = "";
		},
		resetIsEmployeeIdUnique: (state) => {
			state.isEmployeeIdUnique.status = "idle";
			state.isEmployeeIdUnique.errorMessage = "";
			state.isEmployeeIdUnique.data = {
				originalEmployeeId: "",
				employeeId: "",
				isUnique: false,
				checked: false,
			};
		},
		setIsEmployeeIdUnique: (state, action) => {
			state.isEmployeeIdUnique.status = "idle";
			state.isEmployeeIdUnique.errorMessage = "";
			state.isEmployeeIdUnique.data = {
				originalEmployeeId: action.payload.originalEmployeeId,
				employeeId: action.payload.employeeId,
				isUnique: action.payload.isUnique,
				checked: action.payload.checked,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllWorkers.fulfilled, (state, action) => {
				// To handle empty string instead of empty array from API
				state.allWorkers = Array.isArray(action.payload) ? action.payload : [];
				state.fetchAllWorkers.status = "fulfilled";
			})
			.addCase(fetchAllWorkers.pending, (state) => {
				state.fetchAllWorkers.status = "pending";
			})
			.addCase(fetchAllWorkers.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.fetchAllWorkers.status = "error";
				} else {
					state.fetchAllWorkers.status = "error";
					state.fetchAllWorkers.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			// [fetchAllRegularShiftTypes.fulfilled]: (state, action) => {
			// 	const convertTimeToStandard = (time) => {
			// 		try {
			// 			time = time.split(":");

			// 			let hours = Number(time[0]);
			// 			let minutes = Number(time[1]);
			// 			let timeValue;

			// 			if (hours > 0 && hours <= 12) {
			// 				timeValue= "" + hours;
			// 			} else if (hours > 12) {
			// 				timeValue= "" + (hours - 12);
			// 			} else if (hours === 0) {
			// 				timeValue= "12";
			// 			}

			// 			timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
			// 			timeValue += (hours >= 12) ? " PM" : " AM";
			// 			return timeValue;
			// 		} catch (e) {
			// 			return "";
			// 		}
			// 	}
			// 	state.allRegularShiftTypes = Array.isArray(action.payload) ? action.payload.map(rShiftType => {
			// 		const scheduleArray = rShiftType.schedule
			// 			? rShiftType.schedule.length > 0 ?
			// 				rShiftType.schedule : [] : [];
			// 		let nameWithTime = rShiftType.name;
			// 		if (scheduleArray.length > 0) {
			// 			if (Object.prototype.hasOwnProperty.call(rShiftType.schedule[0], "startTime") &&
			// 				Object.prototype.hasOwnProperty.call(rShiftType.schedule[0], "endTime")) {
			// 				nameWithTime = `${rShiftType.name} (${convertTimeToStandard(rShiftType.schedule[0].startTime)} - ${convertTimeToStandard(rShiftType.schedule[0].endTime)})`;
			// 			}
			// 		}
			// 		return {
			// 			...rShiftType,
			// 			nameWithTime: nameWithTime,
			// 		}
			// 	}) : [];
			// 	state.fetchAllRegularShiftTypes.status = "fulfilled";
			// 	state.fetchAllRegularShiftTypes.errorMessage = "";
			// },
			// [fetchAllRegularShiftTypes.pending]: (state) => {
			// 	state.fetchAllRegularShiftTypes.status = "pending";
			// 	state.fetchAllRegularShiftTypes.errorMessage = "";
			// },
			// [fetchAllRegularShiftTypes.rejected]: (state, action) => {
			// 	state.fetchAllRegularShiftTypes.status = "error";
			// 	state.fetchAllRegularShiftTypes.errorMessage = action.payload ?? "An error occurred";
			// },
			.addCase(fetchAllOrganizations.fulfilled, (state, action) => {
				state.allOrganizations = action.payload;
				state.fetchAllOrganizations.status = "fulfilled";
				state.fetchAllOrganizations.errorMessage = "";
			})
			.addCase(fetchAllOrganizations.pending, (state) => {
				state.fetchAllOrganizations.status = "pending";
				state.fetchAllOrganizations.errorMessage = "";
			})
			.addCase(fetchAllOrganizations.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.fetchAllOrganizations.status = "error";
				} else {
					state.fetchAllOrganizations.status = "error";
					state.fetchAllOrganizations.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(fetchOrganizationTypes.fulfilled, (state, action) => {
				state.allOrganizationTypes = action.payload;
				state.fetchOrganizationTypes.status = "fulfilled";
			})
			.addCase(fetchOrganizationTypes.pending, (state) => {
				state.fetchOrganizationTypes.status = "pending";
			})
			.addCase(fetchOrganizationTypes.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.fetchOrganizationTypes.status = "error";
				} else {
					state.fetchOrganizationTypes.status = "error";
					state.fetchOrganizationTypes.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(fetchAllManagers.fulfilled, (state, action) => {
				state.allManagers = action.payload;
				state.fetchAllManagers.status = "fulfilled";
			})
			.addCase(fetchAllManagers.pending, (state) => {
				state.fetchAllManagers.status = "pending";
			})
			.addCase(fetchAllManagers.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.fetchAllManagers.status = "error";
				} else {
					state.fetchAllManagers.status = "error";
					state.fetchAllManagers.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(fetchManageWorkersData.fulfilled, (state, action: any) => {
				const { allWorkers, allSkills, normalizedJobRoles } = action.payload;
				// To handle empty string instead of empty array from api
				state.allWorkers = Array.isArray(allWorkers) ? allWorkers : [];
				state.allJobRoles = normalizedJobRoles;
				state.allSkills = allSkills ? allSkills : [];
				state.fetchManageWorkersData.status = "fulfilled";
			})
			.addCase(fetchManageWorkersData.pending, (state) => {
				state.fetchManageWorkersData.status = "pending";
			})
			.addCase(fetchManageWorkersData.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.fetchManageWorkersData.status = "error";
				} else {
					state.fetchManageWorkersData.status = "error";
					state.fetchManageWorkersData.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(fetchWorkerProfile.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.workerProfile.status = "error";
				} else {
					state.workerProfile.status = "error";
					state.workerProfile.errorMessage =
						action.payload?.message ?? "An error occurred";
					state.workerProfile.profile = null;
					state.workerProfile.scoreItems = null;
				}
			})
			.addCase(fetchWorkerProfile.pending, (state) => {
				state.workerProfile.status = "pending";
				state.workerProfile.errorMessage = "";
				state.workerProfile.profile = null;
				state.workerProfile.scoreItems = null;
			})
			.addCase(fetchWorkerProfile.fulfilled, (state, action) => {
				state.workerProfile.status = "fulfilled";
				state.workerProfile.errorMessage = "";
				state.workerProfile.profile = action.payload;
				if (state.workerProfile.profile) {
					state.workerProfile.profile.scoreItems = {
						// (status === 'C' && isCancelOutsidePeriod)
						cancelledAndOutside: action.payload.scoreItems.filter(
							({ status, isCancelOutsidePeriod }: Worker_ScoreItem) =>
								status === "C" && isCancelOutsidePeriod
						),
						// && (status === 'C' && !isCancelOutsidePeriod)
						cancelledAndInside: action.payload.scoreItems.filter(
							({ status, isCancelOutsidePeriod }: Worker_ScoreItem) =>
								status === "C" && !isCancelOutsidePeriod
						),
						// && (status === 'A' && isAbsent)
						absent: action.payload.scoreItems.filter(
							({ isAbsent }: Worker_ScoreItem) => isAbsent
						),
						tardy: action.payload.scoreItems.filter(
							({ isTardy }: Worker_ScoreItem) => isTardy
						),
					};
				} else {
					state.workerProfile.scoreItems = null;
				}
			})
			.addCase(createNewWorker.fulfilled, (state, action) => {
				state.addWorkerDialog.isOpen = false;
				state.createNewWorker.status = "fulfilled";
			})
			.addCase(createNewWorker.pending, (state) => {
				state.createNewWorker.status = "pending";
			})
			.addCase(createNewWorker.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.createNewWorker.status = "error";
				} else {
					state.createNewWorker.status = "error";
					state.createNewWorker.errorMessage =
						action.payload ?? "An error occurred";
				}
			})
			.addCase(deleteWorkers.fulfilled, (state, action) => {
				const workerIds = action.payload;
				// console.log(workerIds);
				// const index = state.allWorkers.indexOf(workerIds[0]);

				const index = (state.allWorkers as Worker_BasicDetails[]).findIndex(
					(el) => el.id === workerIds[0]
				);
				state.allWorkers.splice(index, 1);
				// console.log(index);

				// workerIds.forEach((id) => {
				// state.allWorkers.splice(state.allWorkers.indexOf(id), 1);
				// });
				state.deleteWorkers.status = "fulfilled";
			})
			.addCase(deleteWorkers.pending, (state) => {
				state.deleteWorkers.status = "pending";
			})
			.addCase(deleteWorkers.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.deleteWorkers.status = "error";
				} else {
					state.deleteWorkers.status = "error";
					state.deleteWorkers.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(editWorker.fulfilled, (state, action) => {
				const workerId = action.payload?.workerId;
				const newWorkerData = action.payload?.data;
				const index = (state.allWorkers as Worker_BasicDetails[]).findIndex(
					(el) => el.id === workerId
				);
				state.allWorkers[index] = newWorkerData;
				// state.editWorkerDialog.isOpen = false;
				state.editWorker.status = "fulfilled";
			})
			.addCase(editWorker.pending, (state) => {
				state.editWorker.status = "pending";
			})
			.addCase(editWorker.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.editWorker.status = "error";
				} else {
					state.editWorker.status = "error";
					const errorMessage = "An error occurred";
					state.editWorker.errorMessage = action.payload
						? action.payload.message
							? action.payload.message
							: errorMessage
						: errorMessage;
					state.editWorker.shifts = action.payload
						? action.payload.shifts
							? action.payload.shifts
							: []
						: [];
				}
			})
			.addCase(getExpLevel.fulfilled, (state, action) => {
				state.expLevel = action.payload;
				state.getExpLevel.status = "idle";
				state.getExpLevel.errorMessage = "";
			})
			.addCase(getExpLevel.pending, (state) => {
				state.getExpLevel.status = "pending";
			})
			.addCase(getExpLevel.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.getExpLevel.status = "error";
				} else {
					state.getExpLevel.status = "error";
					const errorMessage = "An error occurred";
					state.getExpLevel.errorMessage = action.payload
						? action.payload.message
							? action.payload.message
							: errorMessage
						: errorMessage;
					state.expLevel = [];
				}
			})
			.addCase(addSkillInterested.fulfilled, (state) => {
				state.addSkillInterested.status = "fulfilled";
				state.addSkillInterested.errorMessage = "";
			})
			.addCase(addSkillInterested.pending, (state) => {
				state.addSkillInterested.status = "pending";
			})
			.addCase(addSkillInterested.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.addSkillInterested.status = "error";
				} else {
					state.addSkillInterested.status = "error";
					const errorMessage = "An error occurred";
					state.addSkillInterested.errorMessage = action.payload
						? action.payload.message
							? action.payload.message
							: errorMessage
						: errorMessage;
				}
			})
			.addCase(rejectSkillInterested.fulfilled, (state) => {
				state.rejectSkillInterested.status = "fulfilled";
				state.rejectSkillInterested.errorMessage = "";
			})
			.addCase(rejectSkillInterested.pending, (state) => {
				state.rejectSkillInterested.status = "pending";
			})
			.addCase(rejectSkillInterested.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.rejectSkillInterested.status = "error";
				} else {
					state.rejectSkillInterested.status = "error";
					const errorMessage = "An error occurred";
					state.rejectSkillInterested.errorMessage = action.payload
						? action.payload.message
							? action.payload.message
							: errorMessage
						: errorMessage;
				}
			})
			// update skill level
			.addCase(updateWorkerSkillLevel.fulfilled, (state, action: any) => {
				const {
					workerId,
					skillId,
					levelId,
					presentLevelId,
					useObsoleteSkills,
				} = action.payload.payload;

				const priorities = action.payload.priorities;
				const levels = action.payload.levels;
				const skills = action.payload.skills;
				const selectedSkill = skills.find(
					(el: SkillMatrix_SkillPriority) => el.id === skillId
				);
				const isSkillObsolete = selectedSkill.isObsolete;
				if ((useObsoleteSkills && isSkillObsolete) || !isSkillObsolete) {
					const newLevel = levels.find(
						(el: SkillMatrix_SkillLevel) => el.id === levelId
					) ?? {
						level: 1,
					};
					const oldLevel = levels.find(
						(el: SkillMatrix_SkillLevel) => el.id === presentLevelId
					) ?? {
						level: 0,
					};

					const skillPriority = priorities.find(
						(el: PriorityType) => el.id === selectedSkill.priorityId
					) ?? {
						multiplier: 1,
					};

					const index = (state.allWorkers as SkillMatrix_Worker[]).findIndex(
						(el) => el.id === workerId
					);
					const temp = state.allWorkers[index];

					const scoreChange =
						skillPriority.multiplier * (newLevel.level - oldLevel.level);

					const newScore = temp.skillScore + scoreChange;

					state.allWorkers[index] = {
						...temp,
						skills: (temp as SkillMatrix_Worker).skills.map((skill) => {
							return skill.skillId === skillId
								? { ...skill, levelId: levelId }
								: skill;
						}),
						skillScore: newScore,
					};
				}

				state.updateSkillLevel.status = "fulfilled";
				state.updateSkillLevel.errorMessage = "";
			})
			.addCase(updateWorkerSkillLevel.pending, (state) => {
				state.updateSkillLevel.status = "pending";
			})
			.addCase(updateWorkerSkillLevel.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.updateSkillLevel.status = "error";
				} else {
					state.updateSkillLevel.status = "error";
					const errorMessage = "An error occurred";
					state.updateSkillLevel.errorMessage = action.payload
						? action.payload.message
							? action.payload.message
							: errorMessage
						: errorMessage;
				}
			})
			.addCase(fetchSkillLevels.fulfilled, (state, action) => {
				const temp = action.payload.map((el: Skill) => ({
					...el,
					levelId: el.id,
				}));
				state.skillLevels = temp;
				state.fetchSkillLevels.status = "fulfilled";
				state.fetchSkillLevels.errorMessage = "";
			})
			.addCase(fetchSkillLevels.pending, (state) => {
				state.fetchSkillLevels.status = "pending";
			})
			.addCase(fetchSkillLevels.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.fetchSkillLevels.status = "error";
				} else {
					state.fetchSkillLevels.status = "error";
					const errorMessage = "An error occurred";
					state.fetchSkillLevels.errorMessage = action.payload
						? action.payload.message
							? action.payload.message
							: errorMessage
						: errorMessage;
				}
			})
			.addCase(fetchSkillMatrix.fulfilled, (state, action) => {
				state.skillMatrixData = action.payload ?? {
					workers: [],
					totalCount: 0,
				};
				state.fetchSkillMatrix.status = "fulfilled";
				state.fetchSkillMatrix.errorMessage = "";
			})
			.addCase(fetchSkillMatrix.pending, (state) => {
				state.fetchSkillMatrix.status = "pending";
			})
			.addCase(fetchSkillMatrix.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.fetchSkillMatrix.status = "error";
				} else {
					state.fetchSkillMatrix.status = "error";
					const errorMessage = "An error occurred";
					state.fetchSkillMatrix.errorMessage = action.payload
						? action.payload.message
							? action.payload.message
							: errorMessage
						: errorMessage;
				}
			})
			.addCase(fetchNotAssignedSkillLevel.pending, (state) => {
				state.fetchNotAssignedSkillLevel.status = "pending";
			})
			.addCase(fetchNotAssignedSkillLevel.fulfilled, (state, action) => {
				state.fetchNotAssignedSkillLevel.status = "fulfilled";
				state.fetchNotAssignedSkillLevel.NotAssignedSkillLevel = action.payload;
			})
			.addCase(fetchNotAssignedSkillLevel.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.fetchNotAssignedSkillLevel.status = "error";
				} else {
					state.fetchNotAssignedSkillLevel.status = "error";
					state.fetchNotAssignedSkillLevel.errorMessage = action.payload
						? action.payload.message
							? action.payload.message
							: "An error occurred"
						: "An error occurred";
				}
			})
			.addCase(fetchSkillMatrixFilters.fulfilled, (state, action) => {
				const data = action.payload;
				let skills = data.skills ?? [];
				const priorities = data.skillPriorities ?? DUMMY_PRIORITIES_ARRAY;
				skills = appendPriorityToSkills(skills, priorities);

				// Setting all jobs for filter
				if (
					Object.prototype.hasOwnProperty.call(data, "jobs") &&
					Object.prototype.hasOwnProperty.call(data, "jobSkills") &&
					skills
				) {
					const groupedSkillsByPriority = skills.reduce(
						(
							acc: {
								[priorityId: number]: SkillMatrix_SkillPriority[];
							},
							skill: SkillMatrix_SkillPriority
						) => {
							if (!acc[skill.priority.id]) {
								acc[skill.priority.id] = [];
							}
							acc[skill.priority.id].push(skill);
							return acc;
						},
						{}
					);

					const allJobs = data.jobs
						? data.jobs.map((job: SkillMatrix_ResponseJobModel) => {
								const filterJobSkills = data.jobSkills.filter(
									(jobSkill: SkillMatrix_JobSkill) => jobSkill.jobId === job.id
								);
								const filterSkillsByJobId: SkillItem[] = [];
								filterJobSkills.forEach((jobSkill: SkillMatrix_JobSkill) => {
									const item = skills.find(
										(skill: SkillMatrix_SkillPriority) =>
											skill.id === jobSkill.skillId
									);
									if (item) {
										filterSkillsByJobId.push({
											id: item.id,
											jobId: job.id,
											name: item.name,
											skillId: item.id,
											priority: item.priority,
										});
									}
								});

								const priorityMap = new Map<string, Priority>();

								filterSkillsByJobId.forEach((item) => {
									if (!priorityMap.has(String(item.priority.id))) {
										priorityMap.set(String(item.priority.id), {
											...item.priority,
											skills: [],
										});
									}
									priorityMap.get(String(item.priority.id))!.skills!.push(item);
								});

								const uniquePriorities = Array.from(priorityMap.values());

								return {
									...job,
									priorities: uniquePriorities,
									isActive: true,
									skills: [...filterSkillsByJobId],
								};
						  })
						: [];

					state.skillMatrixFiltersAllJobs = allJobs;

					state.skillMatrixFiltersPriorities = Object.keys(
						groupedSkillsByPriority
					).map((priorityId) => {
						const priority = groupedSkillsByPriority[priorityId][0].priority;
						const associatedSkills = groupedSkillsByPriority[priorityId];
						const associatedJobs = allJobs.filter(
							(job: { priorities: JobPriority[] }) =>
								job.priorities.some((w) => w.id === priority.id)
						);

						return {
							...priority,
							skills: associatedSkills,
							jobs: associatedJobs,
						};
					});

					let allNewJobRoles = {};
					if (allJobs.length > 0) {
						allNewJobRoles = allJobs.reduce(
							(
								obj: { [key: string]: SkillMatrix_Job },
								cur: SkillMatrix_Job
							) => ({
								...obj,
								[cur.id]: cur,
							}),
							{}
						);
						state.skillMatrixFiltersJobRoles = allNewJobRoles;
					}
				}

				// Setting organization types
				if (Object.prototype.hasOwnProperty.call(data, "orgTypes")) {
					state.skillMatrixFiltersOrgTypes = data.orgTypes ?? [];
				}

				// Setting organizations
				if (Object.prototype.hasOwnProperty.call(data, "organizations")) {
					state.skillMatrixFiltersOrganizations = data.organizations ?? [];
				}

				// Setting locations
				if (Object.prototype.hasOwnProperty.call(data, "locations")) {
					state.skillMatrixFiltersLocations = data.locations ?? [];
				}

				// Skill Priorities
				if (Object.prototype.hasOwnProperty.call(data, "skillPriorities")) {
					state.skillMatrixFiltersSkillPriorities = data.skillPriorities ?? [];
				}

				// Setting managers
				if (Object.prototype.hasOwnProperty.call(data, "managers")) {
					state.skillMatrixFiltersManagers = data.managers ?? [];
				}

				// Setting work centers
				if (Object.prototype.hasOwnProperty.call(data, "workcenters")) {
					state.skillMatrixFiltersWorkCenters = data.workcenters ?? [];
				}

				// Setting shift patterns
				if (Object.prototype.hasOwnProperty.call(data, "shiftPatterns")) {
					state.skillMatrixFiltersShiftPatterns = data.shiftPatterns ?? [];
				}

				// Setting worker types
				if (Object.prototype.hasOwnProperty.call(data, "workerTypes")) {
					state.skillMatrixFiltersWorkerTypes = data.workerTypes ?? [];
				}

				// Setting skills and skill levels
				if (
					Object.prototype.hasOwnProperty.call(data, "skills") &&
					Object.prototype.hasOwnProperty.call(data, "levels")
				) {
					if (
						skills &&
						skills.length > 0 &&
						data.levels &&
						data.levels.length > 0
					) {
						//forming skill level object
						const temp = skills.map((item: SkillMatrix_Skill) => {
							return {
								...item,
								levels: data.levels,
							};
						});
						state.skillMatrixFiltersSkillLevels = temp[0].levels.map(
							(el: SkillMatrix_SkillLevel) => ({
								...el,
								levelId: el.id,
							})
						);
					}
					state.skillMatrixFiltersSkills = skills;
				}
				state.jobSkillLevelMap = action.payload.jobSkills.reduce(
					(
						acc: any,
						jobSkill: {
							jobId: number;
							skillId: number;
							minSkillLevelId: number;
						}
					) => {
						acc[`${jobSkill.jobId}-${jobSkill.skillId}`] =
							jobSkill.minSkillLevelId;
						return acc;
					},
					{}
				);
				state.fetchSkillMatrixFilters.status = "fulfilled";
				state.fetchSkillMatrixFilters.errorMessage = "";
			})
			.addCase(fetchSkillMatrixFilters.pending, (state) => {
				state.fetchSkillMatrixFilters.status = "pending";
				state.fetchSkillMatrixFilters.errorMessage = "";
			})
			.addCase(fetchSkillMatrixFilters.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.fetchSkillMatrixFilters.status = "error";
				} else {
					state.fetchSkillMatrixFilters.status = "error";
					const errorMessage = "An error occurred";
					state.fetchSkillMatrixFilters.errorMessage = action.payload
						? action.payload.message
							? action.payload.message
							: errorMessage
						: errorMessage;
				}
			})
			.addCase(fetchSkillMatrixDefaultFilters.fulfilled, (state, action) => {
				state.fetchSkillMatrixDefaultFilters.status = "fulfilled";
				state.fetchSkillMatrixDefaultFilters.errorMessage = "";
				const newSkillMatrixAppliedFilters = action.payload
					? {
							...action.payload,
							managers: action.payload.managerId
								? `${action.payload.managerId}`
								: "",
					  }
					: {};
				state.skillMatrixAppliedFilters = newSkillMatrixAppliedFilters;
			})
			.addCase(fetchSkillMatrixDefaultFilters.pending, (state) => {
				state.fetchSkillMatrixDefaultFilters.status = "pending";
				state.fetchSkillMatrixDefaultFilters.errorMessage = "";
			})
			.addCase(
				fetchSkillMatrixDefaultFilters.rejected,
				(state, action: any) => {
					if (action?.payload?.status === 429) {
						state.fetchSkillMatrixDefaultFilters.status = "error";
					} else {
						state.fetchSkillMatrixDefaultFilters.status = "error";
						const errorMessage = "An error occurred";
						state.fetchSkillMatrixDefaultFilters.errorMessage = action.payload
							? action.payload.message
								? action.payload.message
								: errorMessage
							: errorMessage;
					}
				}
			)
			//update
			.addCase(updateWorkerJobExpiry.fulfilled, (state) => {
				state.updateWorkerJobExpiry.status = "fulfilled";
				state.updateWorkerJobExpiry.errorMessage = "";
			})
			.addCase(updateWorkerJobExpiry.pending, (state) => {
				state.updateWorkerJobExpiry.status = "pending";
			})
			.addCase(updateWorkerJobExpiry.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.updateWorkerJobExpiry.status = "error";
				} else {
					const { error, data }: any = action.payload;
					state.updateWorkerJobExpiry.status = "error";
					state.updateWorkerJobExpiry.errorMessage = error;
					// Revert job expiry if fail
					ManageWorkersSlice.caseReducers.setJobExpiry(state, {
						payload: {
							status: !data.status,
							workerId: data.workerId,
							jobId: data.jobId,
						},
					});
				}
			})
			.addCase(getWorkerTypes.pending, (state) => {
				state.getWorkerTypes.status = "pending";
				state.getWorkerTypes.errorMessage = "";
			})
			.addCase(getWorkerTypes.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.getWorkerTypes.status = "error";
				} else {
					state.getWorkerTypes.status = "error";
					state.getWorkerTypes.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(getWorkerTypes.fulfilled, (state, action) => {
				state.getWorkerTypes.status = "fulfilled";
				state.getWorkerTypes.errorMessage = "";
				state.workerTypes = action.payload ?? [];
			})
			.addCase(fetchUserLocations.pending, (state) => {
				state.fetchUserLocations.status = "pending";
				state.fetchUserLocations.errorMessage = "";
			})
			.addCase(fetchUserLocations.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.fetchUserLocations.status = "error";
				} else {
					state.fetchUserLocations.status = "error";
					state.fetchUserLocations.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(fetchUserLocations.fulfilled, (state, action) => {
				state.fetchUserLocations.status = "fulfilled";
				state.fetchUserLocations.errorMessage = "";
				state.userLocations = action.payload ?? [];
			})
			.addCase(getLocationShiftPatterns.pending, (state) => {
				state.getLocationShiftPatterns.status = "pending";
				state.getLocationShiftPatterns.errorMessage = "";
			})
			.addCase(getLocationShiftPatterns.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.getLocationShiftPatterns.status = "error";
				} else {
					state.getLocationShiftPatterns.status = "error";
					state.getLocationShiftPatterns.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(getLocationShiftPatterns.fulfilled, (state, action) => {
				state.getLocationShiftPatterns.status = "fulfilled";
				state.getLocationShiftPatterns.errorMessage = "";
				state.locationShiftPatterns = action.payload ?? [];
			})
			.addCase(getAllShiftTypes.pending, (state) => {
				state.getAllShiftTypes.status = "pending";
				state.getAllShiftTypes.errorMessage = "";
			})
			.addCase(getAllShiftTypes.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.getAllShiftTypes.status = "error";
				} else {
					state.getAllShiftTypes.status = "error";
					state.getAllShiftTypes.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(getAllShiftTypes.fulfilled, (state, action) => {
				state.getAllShiftTypes.status = "fulfilled";
				state.getAllShiftTypes.errorMessage = "";
				state.getAllShiftTypes.data = action.payload ?? [];
			})
			.addCase(deleteWorkerSkills.fulfilled, (state, action: any) => {
				state.deleteWorkerSkills.status = "fulfilled";
				state.deleteWorkerSkills.errorMessage = "";
				//  remove skill from workerprofile jobs
				const skillId = action.payload.skillId;
				const workerJobs = state.workerProfile.profile?.jobs ?? [];
				const otherSkills = state.workerProfile.profile?.otherSkills ?? [];
				const removedSkill = workerJobs.map((job: Worker_Job) => {
					const skills = job.skills.filter(
						(skill) => skill.skillId !== skillId
					);
					return { ...job, skills };
				});
				const removedOtherSkills = otherSkills.filter(
					(skill: OtherSkill) => skill.skillId !== skillId
				);
				if (state.workerProfile.profile) {
					state.workerProfile.profile.jobs = removedSkill;
					state.workerProfile.profile.otherSkills = removedOtherSkills;
				}
			})
			.addCase(deleteWorkerSkills.pending, (state) => {
				state.deleteWorkerSkills.status = "pending";
				state.deleteWorkerSkills.errorMessage = "";
			})
			.addCase(deleteWorkerSkills.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.deleteWorkerSkills.status = "error";
				} else {
					state.deleteWorkerSkills.status = "error";
					state.deleteWorkerSkills.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(addWorkerSkills.fulfilled, (state) => {
				state.addWorkerSkills.status = "fulfilled";
				state.addWorkerSkills.errorMessage = "";
			})
			.addCase(addWorkerSkills.pending, (state) => {
				state.addWorkerSkills.status = "pending";
				state.addWorkerSkills.errorMessage = "";
			})
			.addCase(addWorkerSkills.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.addWorkerSkills.status = "error";
				} else {
					state.addWorkerSkills.status = "error";
					state.addWorkerSkills.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(modifyWorkerSkill.fulfilled, (state, action: any) => {
				state.editWorkerSkill.status = "fulfilled";
				state.editWorkerSkill.errorMessage = "";
				state.editWorkerSkillDialog.isOpen = false;
				state.editWorkerSkillDialog.skillData = null;
				const { skillId, level, levelId, validFrom, validTo, levelName } =
					action.payload;
				const jobs = state.workerProfile.profile?.jobs ?? [];
				const otherSkills = state.workerProfile.profile?.otherSkills ?? [];
				// updating skills mapped to job
				for (const job of jobs) {
					for (const skill of job.skills) {
						if (skill.skillId === skillId) {
							skill.level = level;
							skill.levelName = levelName;
							skill.levelId = levelId;
							skill.validFrom = validFrom;
							skill.validTo = validTo;
						}
					}
				}
				// updating unmapped skills
				for (const skill of otherSkills) {
					if (skill.skillId === skillId) {
						skill.level = level;
						skill.levelName = levelName;
						skill.levelId = levelId;
						skill.validFrom = validFrom;
						skill.validTo = validTo;
					}
				}
				if (state.workerProfile.profile) {
					state.workerProfile.profile.jobs = jobs;
					state.workerProfile.profile.otherSkills = otherSkills;
				}
			})
			.addCase(modifyWorkerSkill.pending, (state) => {
				state.editWorkerSkill.status = "pending";
				state.editWorkerSkill.errorMessage = "";
			})
			.addCase(modifyWorkerSkill.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.editWorkerSkill.status = "error";
				} else {
					state.editWorkerSkill.status = "error";
					state.editWorkerSkill.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(deleteWorkerCertifications.fulfilled, (state, action: any) => {
				state.deleteWorkerCertifications.status = "fulfilled";
				state.deleteWorkerCertifications.errorMessage = "";
				//  remove skill from workerprofile jobs
				const workerCertId = action.payload.workerCertId;

				const certifications = state.workerProfile.profile?.workerCerts ?? [];
				const removedCertifications = certifications.filter(
					(cert: WorkerCert) => cert.workerCertId !== workerCertId
				);
				if (state.workerProfile.profile) {
					state.workerProfile.profile.workerCerts = removedCertifications;
				}
			})
			.addCase(deleteWorkerCertifications.pending, (state) => {
				state.deleteWorkerCertifications.status = "pending";
				state.deleteWorkerCertifications.errorMessage = "";
			})
			.addCase(deleteWorkerCertifications.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.deleteWorkerCertifications.status = "error";
				} else {
					state.deleteWorkerCertifications.status = "error";
					state.deleteWorkerCertifications.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(editWorkerCertification.fulfilled, (state, action) => {
				// state.editWorkerCertificationDialog.isOpen = false;
				state.editWorkerCertificationDialog.certificationData = null;
				state.editWorkerCertification.status = "fulfilled";
				const { companyCertId, validFrom, validTo } = action.payload;
				const certifications = state.workerProfile.profile?.workerCerts ?? [];
				const updatedCertifications = certifications.map((cert: WorkerCert) => {
					if (cert.companyCertId === companyCertId) {
						return { ...cert, validFrom, validTo };
					}
					return cert;
				});
				if (state.workerProfile.profile) {
					state.workerProfile.profile.workerCerts = updatedCertifications;
				}
			})
			.addCase(editWorkerCertification.pending, (state) => {
				state.editWorkerCertification.status = "pending";
				state.editWorkerCertification.errorMessage = "";
			})
			.addCase(editWorkerCertification.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.editWorkerCertification.status = "error";
				} else {
					state.editWorkerCertification.status = "error";
					state.editWorkerCertification.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(addWorkerCertifications.fulfilled, (state) => {
				state.addWorkerCertifications.status = "fulfilled";
				state.addWorkerCertifications.errorMessage = "";
			})
			.addCase(addWorkerCertifications.pending, (state) => {
				state.addWorkerCertifications.status = "pending";
				state.addWorkerCertifications.errorMessage = "";
			})
			.addCase(addWorkerCertifications.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.addWorkerCertifications.status = "error";
				} else {
					state.addWorkerCertifications.status = "error";
					state.addWorkerCertifications.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			})
			.addCase(uploadWorkerCertification.fulfilled, (state) => {
				state.postUploadFiles.successfulUploads += 1;
			})
			.addCase(uploadWorkerCertification.rejected, (state) => {
				state.postUploadFiles.failedUploads += 1;
			})
			.addCase(deleteWorkerCertificationFile.fulfilled, (state) => {
				state.postDeleteFiles.successfulDeletes += 1;
			})
			.addCase(deleteWorkerCertificationFile.rejected, (state) => {
				state.postDeleteFiles.failedDeletes += 1;
			})
			.addCase(downloadWorkerCertification.pending, (state, action) => {
				const { workerCertFileId } = action.meta.arg;
				state.downloadWorkerCertification.status = "pending";
				state.downloadWorkerCertification.errors[workerCertFileId] = undefined;
			})
			.addCase(downloadWorkerCertification.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.downloadWorkerCertification.status = "error";
				} else {
					const { workerCertFileId } = action.meta.arg;
					state.downloadWorkerCertification.status = "error";
					state.downloadWorkerCertification.errors[workerCertFileId] =
						action.payload ?? "An error occurred";
				}
			})
			.addCase(downloadWorkerCertification.fulfilled, (state, action) => {
				const { workerCertFileId } = action.meta.arg;
				state.downloadWorkerCertification.status = "fulfilled";
				state.downloadWorkerCertification.errors[workerCertFileId] = undefined;
			})
			.addCase(isEmployeeIdUnique.fulfilled, (state, action: any) => {
				state.isEmployeeIdUnique.status = "fulfilled";
				state.isEmployeeIdUnique.errorMessage = "";
				state.isEmployeeIdUnique.data = {
					...state.isEmployeeIdUnique.data,
					isUnique: action.payload.isUnique,
					employeeId: action.payload.employeeId,
					checked: true,
				};
			})
			.addCase(isEmployeeIdUnique.pending, (state) => {
				state.isEmployeeIdUnique.status = "pending";
				state.isEmployeeIdUnique.errorMessage = "";
			})
			.addCase(isEmployeeIdUnique.rejected, (state, action: any) => {
				if (action?.payload?.status === 429) {
					state.isEmployeeIdUnique.status = "error";
				} else {
					state.isEmployeeIdUnique.status = "error";
					state.isEmployeeIdUnique.errorMessage =
						action.payload?.message ?? "An error occurred";
				}
			});
	},
});

export const {
	openAddWorkerDialog,
	openEditWorkerSkillDialog,
	closeAddWorkerDialog,
	closeEditWorkerSkillDialog,
	openEditWorkerDialog,
	changeEditWorkerStep,
	closeEditWorkerDialog,
	resetCreateNewWorker,
	resetEditWorker,
	resetDeleteWorkers,
	setWorkerDetails,
	resetWorkerDetails,
	resetAddSkillInterested,
	setJobExpiry,
	resetWorkerJobStatus,
	openWorkerProfile,
	closeWorkerProfile,
	resetSkillLevelStatus,
	resetWorkerTypes,
	resetDeleteWorkerSkills,
	resetAddWorkerSkills,
	resetEditWorkerSkill,
	resetDeleteWorkerCertifications,
	resetSkillMatrixDefaultFilters,
	resetSkillMatrixFilter,
	resetSkillMatrix,
	openEditWorkerCertificationDialog,
	closeEditWorkerCertificationDialog,
	resetAddWorkerCertifications,
	resetEditWorkerCertification,
	setUploadedFiles,
	setDeletedFiles,
	resetPostUploadFiles,
	resetPostDeleteFiles,
	setPostUploadStatus,
	setPostDeleteStatus,
	setDownloadProgress,
	resetDownloadProgress,
	setDownloadError,
	resetFetchAllOrganizations,
	resetIsEmployeeIdUnique,
	setIsEmployeeIdUnique,
} = ManageWorkersSlice.actions;

export default ManageWorkersSlice.reducer;
